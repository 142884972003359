.accordion {
	margin: 0 0 41px;

	& + h2 {
		position: relative;
		padding-top: 35px;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 150px;
			border-top: 1px solid $gray-light;
		}
	}
}

h3.accordion-header {
	margin: 0 0 15px;
	line-height: 1.2;
}

.accordion-toggle {
	color: get-color(accent1);
	font-weight: 800;
	font-size: rem-calc(18);
	line-height: 1.2;
	padding: 0;
	cursor: pointer;
	display: block;
	text-align: left;

	&:hover,
	&:focus {
		color: get-color(primary);
	}

	&.on {

		&::before {
			content: "\f068";
		}
	}

	&::before {
		content: "\f067";
		font-size: rem-calc(20);
		font-weight: normal;
		font-family: $icon-font;
		margin: 0 9px 0 0;
		color: get-color(primary);
	}
}

.accordion-content {
	display: none;
	padding: 10px 25px;
}