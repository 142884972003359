.itemDisplay {
	font-weight: 700;
	font-size: rem-calc(18);

	.listItem + & {
		position: relative;
		padding-top: 35px;

		&::before {
			content: "";
			display: block;
			width: 100px;
			height: 3px;
			background: get-color(primary);
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}