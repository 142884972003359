section.homeHero {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	background-color: #000;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(get-color(accent1), 0.3);
	}
}

.homeHero-caption {
	position: relative;
	min-height: 125px;
	display: flex;
	justify-content: center;
	align-items: center;
	
	@include breakpoint(medium) {
		min-height: 200px;
	}

	@include breakpoint(large) {
		min-height: 350px;
	}

	@include breakpoint(xxlarge) {
		height: 560px;
	}

	h1 {
		color: #fff;
		font-weight: 800;
		text-align: center;

		@include breakpoint(medium) {
			margin: 0 0 100px;
		}
		
		@include breakpoint(xlarge) {
			margin: 0 0 100px;
			font-size: rem-calc(70);
		}
	}
}