.callout {
	background: transparent;
	border: none;
	padding: 0;
	margin: 0 0 2rem;
	font-size: rem-calc(14);

	a {
		display: inline-block;
		margin: 0 15px 0 0;
		font-weight: 700;
	}
}