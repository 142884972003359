.asideBox.asideDownloads {

}

.asideDownloadBox {
	font-size: rem-calc(14);
	margin: 0 0 45px;

	h3 {
		font-size: rem-calc(18);
		font-weight: 700;
		color: $gray-darkest;
		margin: 0 0 11px;
	}

	p {
		margin: 0 0 11px;
		line-height: 1.6;
	}

	a.download {
		font-size: rem-calc(18);
		font-weight: 700;
	}
}