@charset 'utf-8';

@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,800';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/solid';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
//@include foundation-table;

// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
//@include foundation-card;
//@include foundation-dropdown;
@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;

// Menu-based containers
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
//@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import '_includes/_vars',
				'_includes/_mixins',
				'_helpers/_app-variables',
				'_base/_skippy',
				'_base/_base',
				'_layout/_header',
				'_layout/_footer',
				'_layout/_main-nav',
				'_components/_action-row',
				'_components/_breadcrumbs',
				'_components/_buttons',
				'_components/_home-hero',
				'_components/_home-intro',
				'_components/_section-landing',
				'_components/_sub-banner',
				'_components/_tease-box-row',
				'_components/_tease-row',
				'_components/_general-body',
				'_components/_featured-box',
				'_components/_routing-box',
				'_components/_tab-widget',
				'_components/_related-widget',
				'_components/_directory-search',
				'_components/_image-caption',
				'_components/_blog-meta',
				'_components/_contact-widget',
				'_components/_event-detail',
				'_components/_item-display',
				'_components/_list-item',
				'_components/_pagination',
				'_components/_accordion',
				'_components/_staff-box',
				'_components/_staff-intro',
				'_components/_blockquote',
				'_components/_member-detail',
				'_components/_related-news',
				'_components/_aside-box',
				'_components/_aside-share',
				'_components/_aside-related',
				'_components/_aside-downloads',
				'_components/_aside-events',
				'_components/_aside-news',
				'_components/_callout';