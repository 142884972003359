section.teaseBoxRow {
	background: $gray-lighter;
	padding: 25px 0;

	@include breakpoint(xlarge) {
		padding: 100px 0;
	}
}

.teaseBoxRow-inner {

	@include breakpoint(xlarge) {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	@include breakpoint(xxlarge) {
		margin: 0 90px;
	}
}

a.teaseBox,
.teaseBox {
	margin: 0 0 2rem;
	display: block;
	color: $gray-darker;

	@include breakpoint(medium) {
		display: flex;
		align-items: center;
	}

	@include breakpoint(xlarge) {
		width: calc(50% - 40px);
	}

	@include breakpoint(xxlarge) {
		padding-right: 55px;
	}

	&:hover,
	&:focus {
		text-decoration: none;

		.teaseBox-content p:not(.text-right) {
			text-decoration: underline;
		}
	}
}

.teaseBox-thumbContainer {
	position: relative;
	margin: 0 0 21px;
	
	@include breakpoint(medium) {
		min-width: 150px;
		max-height: 150px;
		min-height: 150px;
	}

	&::before {
		content: "";
		display: none;
		position: absolute;
		top: 9px;
		left: -9px;
		background: get-color(secondary);
		width: 100%;
		height: 100%;

		@include breakpoint(large) {
			display: block;
		}
	}
}

.teaseBox-thumb {
	position: relative;

	@include breakpoint(medium) {
		object-fit: cover;
		width: 150px;
		height: 150px;
	}
}

.teaseBox-content {

	@include breakpoint(medium) {
		padding: 0 0 0 25px;
		font-size: rem-calc(18);
	}

	h2 {

		@include breakpoint(medium) {
			font-size: rem-calc(30);
			font-weight: 300;
			color: get-color(accent1);
		}

		a {
			color: get-color(accent1);
		}
	}
}