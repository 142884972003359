section.membertDetail {
	padding-top: 0;
}

.memberDetailTop {
	display: flex;
	flex-direction: column;

	@include breakpoint(xlarge) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
		box-shadow: 0 0 9px rgba(#000, 0.15);
		margin: 30px 0 65px;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}
}

.memberDetailTop-lead {
	background: get-color(accent1);
	padding: 25px 20px;
	margin: 0 -20px;
	order: 1;
	width: calc(100% + 40px);

	@include breakpoint(xlarge) {
		order: 1;
		width: auto;
		margin-top: -25px;
		padding: 20px 40px 20px 20px;
	}
	
	h1 {
		color: #fff;
		margin: 0;

		@include breakpoint(xlarge) {
			display: inline-block;
		}
	}
}

.memberDetailTop-actions {
	order: 3;
	font-size: rem-calc(18);
	font-weight: 700;
	margin: 0 0 25px;

	@include breakpoint(xlarge) {
		order: 2;
		margin: 0 0 0 auto;
		padding: 15px 25px 0 0;
	}

	@include breakpoint(xxlarge) {
		padding-top: 35px;
		padding-right: 35px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		@include breakpoint(xxlarge) {
			display: flex;
		}
	}

	li {
		margin: 0 0 21px;

		@include breakpoint(large) {
			margin: 0 0 0 25px;
		}

		a {

			@include breakpoint(large) {
				display: block;
				text-align: right;
			}
		}
	}
}

.memberDetailTop-content {
	order: 2;
	box-shadow: 0 0 9px rgba(#000, 0.15);
	padding: 25px 20px;
	margin: 0 -20px 35px;
	font-size: rem-calc(16);

	@include breakpoint(large) {
		display: flex;
		align-items: flex-start;
		padding: 30px 20px 1px;
	}

	@include breakpoint(xlarge) {
		order: 3;
		box-shadow: none;
		margin: 0;
		width: 100%;
		padding: 30px 25px 1px 55px;
	}

	.col {

		&:first-child {
			min-width: 250px;

			@include breakpoint(large) {
				padding-top: 14px;
			}
		}

		&:last-child {

			@include breakpoint(large) {
				margin-top: -11px;
				padding: 0 0 0 35px;
			}
		}
	}

	h2 {
		font-size: rem-calc(20);
		font-weight: 300;

		@include breakpoint(large) {
			font-size: rem-calc(30);
		}

		&.glance,
		&.hours {
			display: flex;
			align-items: center;
			margin: 0 0 21px;

			@include breakpoint(xlarge) {
				margin: 0 0 13px;
			}

			span.icon {
				max-width: 35px;
				height: 35px;
				padding: 0;

				@include breakpoint(large) {
					max-width: 60px;
					height: 60px;
				}

				svg {
					width: 35px;
					height: auto;

					@include breakpoint(large) {
						width: 60px;
					}

					path {
						fill: get-color(primary);
					}

					circle {
						stroke: get-color(primary);
					}
				}
			}

			span {
				display: inline-block;
				padding: 0 0 0 11px;
			}
		}
	}

	.location {
		font-weight: 700;
		display: inline-block;
		margin: 0 0 15px;
	}

	a.web {
		font-weight: 700;
		text-decoration: underline;
		color: $gray-darker;
		display: inline-block;
		margin: 0 0 25px;

		&:hover {
			color: get-color(primary);
		}
	}
}

.memberDetailBox {
	background: $gray-lighter;
	margin: 0 -25px 15px;
	padding: 21px 25px;

	@include breakpoint(large) {
		margin: 0 0 15px;
		padding: 15px 15px 0;
	}

	@include breakpoint(xlarge) {
		margin: 0 -10px 15px;
	}
}

.memberDetailAsset-wrapper {

	@include breakpoint(medium) {
		display: flex;
		flex-wrap: wrap;
	}

	@include breakpoint(large) {
		margin: 0;
		padding: 0 0 0 70px;
	}
}

.memberDetailAsset {

	@include breakpoint(medium) {
		width: 50%;
		padding: 0 25px 0 0;
		margin: 0 0 7px;
	}

	@include breakpoint(xlarge) {
		width: 33.333333%;
	}
}

h3.memberDetailAsset-title {
	font-size: rem-calc(12);
	font-weight: 700;
	text-transform: uppercase;
	color: $gray-dark;
	margin: 0;
	line-height: 1.2;

	& + p {
		font-size: rem-calc(18);
	}
}

p.memberDetailAsset-value {
	font-size: rem-calc(20);
}

.memberItem-wrapper {
	margin: 0 0 2rem;

	@include breakpoint(medium) {
		display: flex;
		flex-wrap: wrap;
	}

	@include breakpoint(xlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(8);
		@include xy-cell-offset(2, $gutters: 0);
	}

	h2 {
		font-size: rem-calc(24);
		font-weight: 300;
		margin: 0 0 25px;
		min-width: 100%;

		@include breakpoint(large) {
			font-size: rem-calc(30);
		}
	}

	h3 {
		font-size: rem-calc(18);
		font-weight: 700;
		color: $gray-darkest;
		margin: 0;
	}

	.mouse {
		font-size: rem-calc(14);
		margin-top: -11px;
	}
}

.memberItem {
	font-size: rem-calc(16);
	margin: 0 0 25px;

	@include breakpoint(medium) {
		width: 50%;
		padding: 0 25px 0 0;
		margin: 0 0 15px;
	}

	@include breakpoint(large) {
		width: 33.333333%;
	}
}