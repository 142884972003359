.asideBox.asideRelated {

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		&:empty,
		.isEmpty {
			display: none;
		}
	}

	li {
		font-size: rem-calc(18);
		font-weight: 700;
		margin: 0 0 9px;

		a {
			text-decoration: underline;
			color: $gray-darkest;

			&:hover,
			&:focus {
				color: get-color(primary);
				text-decoration: underline;
			}
		}

		ul {
			margin: 11px 0 25px;
		}

		li {
			font-size: rem-calc(16);
			font-weight: 400;
			padding: 0 0 0 21px;
			line-height: 1.4;
			margin: 0 0 11px;

			a {
				text-decoration: none;
				color: $gray-darker;
			}
		}
	}
}