.eventInfoBox {
	box-shadow: 0 0 9px rgba(#000, 0.2);
	margin: 1rem -20px 2rem;
	padding: 20px;
	border-radius: $global-radius;

	@include breakpoint(xlarge) {
		margin: 2rem 0 2rem -20px;
	}

	h2 {
		color: $gray-darker;
		font-size: rem-calc(18);
		margin: 0 0 25px;

		@include breakpoint(large) {
			margin: 0 0 35px;
		}
	}

	ul.links {
		list-style: none;
		padding: 0;
		margin: 0 0 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		li {
			width: 100%;
			margin: 0.8rem 0;
			position: relative;

			@include breakpoint(medium) {
				width: 33%;
				margin: 0;
			}

			&:first-child {

				@include breakpoint(medium) {
					width: 27%;
				}
			}
		}

		span,
		a {
			color: $gray-darker;
			font-size: rem-calc(18);
			font-weight: 700;

			&.type {
				display: flex;
				align-items: center;

				span {
					display: inline-block;
					margin-left: 9px;
				}

				svg {

					path {
						fill: get-color(primary);
					}

					circle {
						stroke: get-color(primary);
					}
				}
			}
		}

		a {

			&:hover,
			&:focus {
				color: get-color(primary);
			}
		}

		ul.atcb-list {
			display: none;
			box-shadow: 0 0 7px rgba(#000, 0.1);
			position: absolute;
			top: 100%;
			left: 0;
			list-style: none;
			padding: 15px;
			margin: 0;
			background: #fff;
			width: 200px;
			border: 1px solid $gray-light;
			border-radius: $global-radius;

			li {
				padding: 0;
				margin: 0;
				width: 100%;
			}

			a {
				font-size: rem-calc(14);
			}
		}
	}

	p {

		&:last-child {
			margin: 0;
		}
	}
}

ul.eventActions {
	list-style: none;
	margin: 2rem 0 2rem;
	padding: 0;

	@include breakpoint(large) {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 3rem 0;
		font-size: rem-calc(18);
	}

	li {
		margin: 0.6rem 0;

		@include breakpoint(large) {
			width: auto;
			margin: 0 18px 0 0;
		}

		a {
			font-weight: 700;

			@include breakpoint(large) {
				font-size: rem-calc(18);
			}
		}
	}

	.button {
		margin: 0;
		padding-left: 25px;
		padding-right: 25px;
	}

	p {
		margin: 0;
	}
}

.eventLocation {
	padding: 20px 0;

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 30px 0;
	}

	h2 {
		margin: 0 0 25px;
	}
}

.eventLocation-map {
	position: relative;
	margin: 0 0 1rem;

	@include breakpoint(large) {
		width: 58%;
		margin: 0;
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 60%;

		@include breakpoint(large) {
			padding: 0 0 70%;
		}
	}

	iframe,
	.EventMap {
		position: absolute !important;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}

.eventLocation-content {
	font-size: rem-calc(16);

	@include breakpoint(large) {
		width: 35%;
	}

	h3 {
		@extend h5;
		font-size: rem-calc(18);
	}
}