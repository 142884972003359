@mixin bp-mobile {
  @media (max-width: #{$max-width}) {
    @content;
  }
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 1) + px;
  font-size: ($sizeValue * 0.1) + rem;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}