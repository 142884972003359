.breadcrumbs {
	font-size: rem-calc(13);
	color: $gray-dark;
	padding: 11px 0 0;

	@include breakpoint(xlarge) {
		padding: 23px 0 5px;
	}

	ul {
		margin: 0;
		padding: 0;

		@include breakpoint(medium) {
			display: flex;
			list-style: none;
			flex-wrap: wrap;
		}
	}

	li {
		display: inline;

		@include breakpoint(medium) {
			display: inline-block;
		}
	}

	a {
		color: $gray-dark;

		&::after {
			content: ">";
			display: inline-block;
			margin: 0 13px;
		}
	}
}

.breadcrumbs-inner {

	@include breakpoint(xlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}
}