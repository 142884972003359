footer {
	color: #fff;
	background: get-color(primary);

	a {
		color: #fff;

		&:hover {
			color: #fff;
		}

		&:focus {
			outline-color: #fff;
			color: #fff;
		}
	}
}

.footerTop {
	background: #2166D8 url(../img/stripe-bg.jpg) 0 100% repeat-x;
}

.footerTop-inner {
	padding: 40px 0;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint(large) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}
}

.footerLogo {
	margin: 0 0 40px;

	@include breakpoint(large) {
		margin: 0;
	}

	svg {
		width: auto;
		height: 60px;
	}

	path {
		fill: #fff;
	}
}

.footerLinks {
	text-align: center;
	font-size: rem-calc(16);
	font-weight: 700;
	margin: 0 0 15px;

	@include breakpoint(large) {
		margin: 0 0 0 auto;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		@include breakpoint(large) {
			display: flex;
		}
	}

	li {
		margin: 0 0 13px;

		@include breakpoint(large) {
			margin: 0 10px;
		}

		@include breakpoint(xlarge) {
			margin: 0 20px;
		}
	}
}

.footerSocial {
	font-size: rem-calc(17);

	@include breakpoint(large) {
		font-size: rem-calc(20);
	}

	@include breakpoint(xlarge) {
		margin-left: 19px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		justify-content: center;
	}

	li {
		margin: 0 9px;
	}

	a {

		&:hover,
		&:focus {
			color: get-color(secondary);
		}
	}
}

.footerCredits {
	color: #fff;
	background: get-color(accent1);
	text-align: center;
	font-size: rem-calc(14);
	padding: 25px 0;

	p {
		margin: 0;
	}

	a {
		color: #fff;

		&:focus {
			outline-color: #fff;
		}
	}
}

.footerCredits-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 25px;

	@include breakpoint(xlarge) {
		flex-direction: row;
		justify-content: space-between;
		padding: 0;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}
}

.footerCredits-left {
	order: 2;

	@include breakpoint(xlarge) {
		order: 1;
	}
}

.footerCredits-right {
	order: 1;
	font-weight: 700;
	margin: 0 0 25px;

	@include breakpoint(xlarge) {
		margin: 0;
		order: 2;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
	}

	.button,
	.button.hollow {
		margin: 0 0 0 15px;
		border-width: 2px;
		border-radius: 4px;
		padding: 6px 11px 5px;
		font-size: rem-calc(12);
		text-transform: uppercase;
		font-weight: 800;
	}
}