.blockquote-wrapper {
	padding: 20px 0;

	@include breakpoint(large) {
		padding: 30px 0;
	}
}

.blockquote {
	box-shadow: 0 0 13px rgba(#000, 0.1);
	background: get-color(primary);
	border-radius: $global-radius;
	font-size: rem-calc(18);
	color: #fff;
	display: flex;
	align-items: center;
	padding: 45px 25px 25px;
	position: relative;

	@include breakpoint(large) {
		padding: 25px 25px 25px 0;
		margin-left: 90px;
	}

	@include breakpoint(xxlarge) {
		margin-left: 55px;
	}
}

.blockquote-thumb {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);

	@include breakpoint(large) {
		position: relative;
		top: -4px;
		left: -90px;
		transform: none;
		min-width: 180px;
	}

	&::before {
		content: "";
		display: none;
		position: absolute;
		top: 9px;
		left:-9px;
		width: 100%;
		height: 100%;
		background: get-color(secondary);

		@include breakpoint(large) {
			display: block;
		}
	}

	img.thumb {
		width: 70px;
		height: 70px;
		object-fit: cover;
		position: relative;
		display: none;

		@include breakpoint(large) {
			display: block;
			width: 180px;
			height: 180px;
			object-fit: cover;
		}
	}

	img.iconQuote {
		width: 60px;
		height: auto;

		@include breakpoint(large) {
			position: absolute;
			top: 21px;
			right: 0;
			transform: translate(50%, 0);
		}
	}
}

.blockquote-content {
	flex-grow: 1;

	@include breakpoint(large) {
		padding: 0;
		position: relative;
		margin-left: -21px;
		width: calc(100% + 21px);
	}

	cite {
		color: #fff;
		font-style: normal;
		font-weight: 700;
		font-size: rem-calc(18);
		text-align: right;
	}
}