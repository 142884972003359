section.generalBody {
	max-width: 100%;
	overflow: hidden;

	&.metaHasPhoto main {

		h1 {

			@include breakpoint(large) {
				margin-left: 140px;	
			}

			@include breakpoint(xxlarge) {
				margin-left: 0;
			}
		}

		.itemDisplay,
		.pagination {
			
			@include breakpoint(large) {
				margin-left: 140px;	
			}

			@include breakpoint(xxlarge) {
				margin-left: 0;
			}
		}
	}

	main,
	div.main {
		max-width: 100%;
		position: relative;
		
		@include breakpoint(xlarge) {
			@include xy-cell(9);
			padding-right: 35px;
		}

		@include breakpoint(xxlarge) {
			@include xy-cell(8);
			@include xy-cell-offset(1, $gutters: 0);
			padding: 0 40px 50px 90px;
		}

		.arrowLink {

			@include breakpoint(medium) {
				font-size: rem-calc(18);
				font-weight: 700;
			}
		}
	}

	aside,
	div.aside {
		background: $gray-lighter;
		width: calc(100% + 40px);
		position: relative;
		margin: 0 -20px 40px;
		padding: 25px 20px;

		@include breakpoint(xlarge) {
			@include xy-cell(3, $gutters: 0);
			padding: 25px 20px 25px 31px;
			margin: 70px 0 -45px;
		}

		&::after {
			content: "";
			display: none;

			@include breakpoint(xlarge) {
				display: block;
				position: absolute;
				top: 0;
				left: 100%;
				right: -9999px;
				bottom: 0;
				background: $gray-lighter;
			}
		}
	}
}