.staffIntro {
	background: $gray-lighter;
	margin: 0 -20px 35px;
	padding: 0 20px 25px;
	position: relative;

	@include breakpoint(xlarge) {
		margin: 11px 0 35px;
		padding: 25px 20px;
		min-width: 100%;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
		min-width: 1px;
	}

	p {

		&:last-child {
			margin: 0;
		}
	}
}

.staffIntro-lead {
	
	@include breakpoint(xlarge) {
		position: absolute;
		top: -21px;
		left: 0;
		background: get-color(accent1);
		padding: 15px 25px 21px 65px;
		@include xy-cell(5);
		margin: 0;
		min-height: 125px;
	}

	@include breakpoint(xxlarge) {
		padding-left: 9px;
		width: 240px;
	}

	&::before {
		content: "";

		@include breakpoint(xlarge) {
			display: block;
			position: absolute;
			top: 0;
			left: -999px;
			right: 100%;
			height: 100%;
			background: get-color(accent1);
		}
	}

	.EditMode & {

		h1 {
			color: #000;
		}
	}

	h1 {
		background: get-color(accent1);
		color: #fff;
		margin: 0 -20px 15px;
		padding: 15px 20px;

		@include breakpoint(xlarge) {
			margin: 0;
			background: transparent;
			padding: 0;
		}
	}
}

.staffIntro-torch {
	display: none;

	@include breakpoint(xlarge) {
		position: absolute;
		top: 15px;
		left: -5px;
		display: block;
	}

	@include breakpoint(xxlarge) {
		left: -50px;
	}

	svg {
		width: 60px;
		height: 60px;
	}
}

.staffIntro-content {
	min-height: 50px;

	@include breakpoint(xlarge) {
		@include xy-cell-offset(5);
		min-height: 100px;
	}

	@include breakpoint(xxlarge) {
		margin-left: 270px;
		max-width: 500px;
	}
}