a#skippy {
    background: get-color(primary);
    display: block;
    padding: 1em;
    outline: 0;
    z-index: 100000;
    position: absolute;
    color: #fff;
    font-weight: 800;
    position: fixed;
    top: 0;
    left: 0;

    &:focus {
        position: absolute !important;
    }

    &.show-on-focus {
        position: absolute;
    }

    &:hover {
        background: smart-scale(get-color(primary), 40%, 50%);
    }
}