.staffBox-wrapper {
	display: flex;
	flex-direction: column;

	@include breakpoint(medium) {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.staffBox {
	margin: 0 0 1.2rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	font-size: rem-calc(14);

	@include breakpoint(medium) {
		width: 50%;
		padding: 0 25px 0 0;
	}
}

.staffBox-thumb {
	position: relative;
	width: 55px;

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: -3px;
		left: -3px;
		width: 100%;
		height: 100%;
		background: get-color(secondary);
	}

	img {
		object-fit: cover;
		width: 55px;
		height: 55px;
		position: relative;
	}
}

.staffBox-content {
	flex-grow: 1;
	padding: 0 0 0 15px;

	h4 {
		font-size: rem-calc(18);
		font-weight: 400;
		color: $gray-darkest;
		margin: 0 0 7px;
	}

	a {
		text-decoration: underline;
	}
}