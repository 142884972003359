.routingBox-widget {
	padding: 20px 0;

	@include breakpoint(large) {
		padding: 30px 0;
	}
}

.routingBox-wrapper {

	@include breakpoint(medium) {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin: 0 -17px;
	}
}

.routingBox,
a.routingBox {
	color: $gray-darker;
	font-size: rem-calc(16);
	margin: 0 0 2rem;
	display: block;

	@include breakpoint(medium) {
		width: calc(33.333333% - 34px);
		margin: 0 17px 2rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	h3 {
		color: $gray-darkest;
		font-size: rem-calc(18);
		font-weight: 700;

		a {
			color: $gray-darkest;

			&:hover,
			&:focus {
				text-decoration: underline;
				color: get-color(primary);
			}
		}
	}

	p {
		margin: 0 0 9px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.arrowLink {
		color: get-color(accent1);
		font-size: rem-calc(18);
		font-weight: 700;

		&:hover,
		&:focus {
			color: get-color(primary);
		}
	}
}

.routingBox-thumb {
	margin: 0 0 15px;
	position: relative;
	overflow: hidden;
	display: block;

	@include breakpoint(medium) {
		margin: 0 0 21px;
	}

	&::after {

		@include breakpoint(medium) {
			content: "";
			display: block;
			padding: 0 0 64%;
		}
	}

	img {
		
		@include breakpoint(medium) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: auto;
			width: auto;
			min-width: 100%;
			min-height: 100%;
		}
	}
}