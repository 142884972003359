.directorySearch {
	box-shadow: 0 0 9px rgba(#000, 0.2);
	margin: 30px -20px 40px;
	padding: 25px 20px 40px;

	@include breakpoint(xlarge) {
		margin: 30px 0 40px -15px;
		padding-left: 15px;
		border-radius: $global-radius;
	}

	@include breakpoint(xxlarge) {
		margin: 40px -30px;
		padding: 25px 25px 40px;
	}
}

.directorySearch-input {
	margin: 0 0 35px;

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
	}

	input[type=text] {
		height: 50px;
		padding: 0 15px;
		border: 3px solid get-color(primary);
		border-radius: 3px;
		margin: 0 0 11px;

		@include placeholder {
			color: $gray-dark;
		}
	}

	input[type="submit"] {
		height: 50px;
		line-height: 50px;
		width: 100%;
		padding-top: 0;
		padding-bottom: 0;

		@include breakpoint(large) {
			width: auto;
			margin-left: 11px;
		}
	}
}

.directorySearch-filter {

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	h3 {
		@extend h4;
		text-transform: uppercase;

		@include breakpoint(medium) {
			font-size: rem-calc(18);
		}

		@include breakpoint(large) {
			min-width: 100%;
			margin: 0 0 19px 15px;
		}
	}

	select {
		height: 50px;
	}
}

.dropdownWrapper.initials {

	@include breakpoint(large) {
		display: none;
	}

	select {
		border-color: $gray-dark;

		&:focus,
		&:active {
			border-color: get-color(primary);
		}
	}
}

.dropdownWrapper.population {

	@include breakpoint(large) {
		min-width: 220px;
	}

	select {
		border-color: $gray-dark;

		&:focus,
		&:active {
			border-color: get-color(primary);
		}
	}
}

.directoryInitialBox-wrapper {
	display: none;

	@include breakpoint(large) {
		display: block;
		flex-grow: 1;
		display: flex;
		margin: 0 15px 0 0;
	}

	@include breakpoint(xxlarge) {
		max-width: calc(100% - 235px);
	}

	input[type="radio"] {
		opacity: 0;
		position: absolute;

		& + label {
			height: 50px;
			line-height: 44px;
			padding: 0 8px;
			margin: 0 11px 0 0;
			flex-grow: 1;
			border: 3px solid $gray;
			color: $gray-darker;
			border-radius: 3px;
			text-align: center;
			font-weight: 700;
			font-size: 1.13rem;

			&:hover,
			&:focus,
			&:active {
				border-color: get-color(primary);
				color: get-color(primary);
			}
		}

		&:hover + label,
		&:focus + label,
		&:active + label {
			border-color: get-color(primary);
			color: get-color(primary);
		}
	}

	button,
	.button.hollow {
		height: 50px;
		line-height: 44px;
		padding: 0 8px;
		margin: 0 11px 0 0;
		flex-grow: 1;
		border-color: $gray;
		color: $gray-darker;

		&:hover,
		&:focus,
		&:active {
			border-color: get-color(primary);
			color: get-color(primary);
		}
	}
}

.directoryResults {
	font-size: rem-calc(18);
	font-weight: 700;
	margin: 0 0 40px;
}

.directoryMember-wrapper {
	
	@include breakpoint(medium) {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: flex-start;
	}

	@include breakpoint(xlarge) {
		padding: 15px 0 0;
	}

	a {
		color: $gray-darkest;
		text-decoration: underline;
		display: inline-block;
		width: 100%;
		margin: 0 0 17px;

		@include breakpoint(medium) {
			width: 45%;
			padding: 0 35px 0 0;
			display: block;
		}

		&:hover,
		&:focus {
			color: get-color(primary);
		}
	}
}