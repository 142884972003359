.blogMeta {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin: 0 0 2rem;

	@include breakpoint(large) {
		position: relative;
	}

	@include breakpoint(xxlarge) {
		position: static;
	}

	&.metaHasPhoto {

		@include breakpoint(large) {
			min-height: 100px;
		}

		@include breakpoint(xxlarge) {
			min-height: 1px;
		}
	}

	.listItem & {
		margin: 0 0 1.2rem;

		&.metaHasPhoto {

			@include breakpoint(large) {
				min-height: 120px;
			}
	
			@include breakpoint(xxlarge) {
				min-height: 1px;
			}
		}
	}
}

.blogMeta-photoContainer {
	overflow: hidden;
	position: relative;
	min-width: 60px;
	max-width: 60px;
	max-height: 60px;

	@include breakpoint(medium) {
		min-width: 90px;
		max-width: 90px;
		max-height: 90px;
	}

	@include breakpoint(large) {
		position: absolute;
		top: 0;
		left: 0;
		min-width: 125px;
		max-width: 125px;
		max-height: 125px;
	}

	@include breakpoint(xxlarge) {
		transform: translate(0, 0);
		@include xy-cell-offset(1, $gutters: 0);
		max-height: 300px;
		left: -145px;
	}

	.listItem & {
		display: none;

		@include breakpoint(large) {
			display: block;
		}
		
		@include breakpoint(xxlarge) {
			transform: translate(0, 0);
			margin: 0;
			left: -150px;
		}

		@media (min-width: 1480px) {
			min-width: 230px;
			max-width: 230px;
			margin-left: 0;
			max-height: 230px;
			//left: -251px;
		}
	}

	&::after {
		content: "";
		display: block;
		padding: 0 0 100%;
	}

	img {
		width: 100%;
		height: 60px;
		object-fit: cover;

		@include breakpoint(medium) {
			width: 90px;
			height: 90px;
		}

		@include breakpoint(large) {
			width: 125px;
			height: 125px;
		}

		@include breakpoint(xxlarge) {
			height: 125px;
		}

		@media (min-width: 1480px) {
			//height: 230px;
			//width: 230px;
		}
	}

	*::-ms-backdrop, img {
		height: auto !important;
	}
}

.blogMeta-content {
	flex-grow: 1;
	padding: 0;

	.metaHasPhoto & {
		padding: 0 0 0 21px;

		@include breakpoint(large) {
			padding: 9px 0 0 140px;
		}

		@include breakpoint(xxlarge) {
			padding: 9px 0 0;
		}
	}

	.listItem .metaHasPhoto & {
		padding: 0;

		@include breakpoint(large) {
			padding: 0 0 0 140px;
		}

		@include breakpoint(xxlarge) {
			padding: 0;
		}
	}

	p {

		&:last-child {
			margin: 0;
		}
	}
}

.blogMeta-date {
	font-size: rem-calc(12);
	font-weight: 700;
	color: $gray-darkest;
	text-transform: uppercase;
	margin: 0;
	width: 100%;
}

.blogMeta-author {
	font-size: rem-calc(16);
	color: $gray-dark;

}