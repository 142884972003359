section.homeIntro {
	background: $gray-lighter;
}

.homeIntro-inner {

	@include breakpoint(medium) {
		padding: 31px 25px 35px;
		box-shadow: 0 0 7px rgba(#000, 0.1);
		background: #fff;
		top: 0;
		margin: -100px 0 30px;
		position: relative;
		border-radius: 5px;
	}

	@include breakpoint(large) {
		display: flex;
		align-items: flex-start;
		padding: 0;
		box-shadow: none;
		background: transparent;
	}

	@include breakpoint(xlarge) {
		//top: -155px;
		margin-top: -155px;
		margin-bottom: 15px;
	}
}

.homeIntro-box {

	@include breakpoint(medium) {
		position: relative;
		font-size: rem-calc(20);
		margin: 0 0 25px;
	}

	@include breakpoint(large) {
		@include xy-cell(9, $gutters: 0);
		padding: 31px 25px 0;
		box-shadow: 0 0 7px rgba(#000, 0.1);
		background: #fff;
		margin: 0;
		border-radius: 5px;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(7, $gutters: 0);
		@include xy-cell-offset(1);
		padding: 25px 60px 30px
	}

	h2 {
		
		@include breakpoint(medium) {
			font-size: rem-calc(38);
			font-weight: 300;
			color: get-color(accent1);
			margin: 0 0 19px;
		}
	}

	

	p {
		
		@include breakpoint(medium) {
			margin: 0 0 29px;
		}

		&:last-child {

			@include breakpoint(medium) {
				margin: 0;
			}
		}
	}
}

.homeIntro-action {

	@include breakpoint(large) {
		@include xy-cell(3, $gutters: 0);
		background: get-color(accent1);
		margin-top: 35px;
		height: 100px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
	}

	@include breakpoint(xlarge) {
		margin-top: 58px;
		font-size: rem-calc(18);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(2, $gutters: 0);
	}

	a {

		@include breakpoint(large) {
			color: #fff;
		}
	}

	.arrowLink {

		&::after {

			@include breakpoint(large) {
				color: get-color(secondary);
			}
		}
	}
}

.homeIntro-torch {
	display: none;

	@include breakpoint(xxlarge) {
		position: absolute;
		top: 25px;
		left: -35px;
		display: block;
	}
}