.relatedWidget {
	padding: 20px 0;

	@include breakpoint(large) {
		padding: 30px 0;
	}

	h2 {
		color: $gray-darkest;
	}

	ul {
		list-style: none;
		padding: 0.8rem 0;
		margin: 0;
	}

	li {
		line-height: 1.2;
		margin: 0 0 1.2rem;

		a {
			color: get-color(accent1);
			font-weight: 700;

			&:hover,
			&:focus {
				color: get-color(primary);
				text-decoration: underline;
			}
		}
	}
}