.listItem {
	margin: 0 0 35px;
	position: relative;

	@include breakpoint(large) {
		margin: 0 0 50px;
	}

	.metaHasPhoto & {

		@include breakpoint(large) {
			padding-left: 140px;
		}

		@include breakpoint(xxlarge) {
			padding-left: 0;
		}
	}

	.itemDisplay + & {
		margin-top: 25px;

		@include breakpoint(large) {
			margin-top: 40px;
		}
	}

	h2 {
		margin: 0 0 7px;
		font-size: rem-calc(24);
	}
}