.asideBox {
	margin: 0 0 2rem;

	.asideHeader {
		padding: 0 0 25px;
		margin: 0 0 25px;
		position: relative;

		&::after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 100px;
			height: 3px;
			background: get-color(primary);
		}
	}
}