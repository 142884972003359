section.actionRow {
	background: $gray-lighter;
	padding: 30px 0;

	@include breakpoint(xlarge) {
		padding: 65px 0;
	}

	@include breakpoint(xxlarge) {
		padding: 100px 0;
	}
}

.actionRow-inner {

	@include breakpoint(large) {
		//@include xy-cell(10);
		//@include xy-cell-offset(1);
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.actionRow-thumbContainer {
	position: relative;

	@include breakpoint(large) {
		@include xy-cell(4, $gutters: 0);
	}

	@include breakpoint(xlarge) {
		@include xy-cell(5);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(4, $gutters: 0);
		//@include xy-cell-offset(1);
		margin-left: 90px;
	}
}

.actionRow-thumb {
	position: relative;
	margin: 0 0 25px;

	@include breakpoint(medium) {
		width: 100%;
		height: auto;
		margin: 0;
	}
}

.actionRow-content {

	@include breakpoint(large) {
		padding: 0 0 0 30px;
	}

	@include breakpoint(xlarge) {
		padding: 0;
		font-size: rem-calc(20);
		line-height: 1.7;
		@include xy-cell(7);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(6);
		padding: 0 0 0 25px;
	}

	h2 {

		@include breakpoint(medium) {
			font-size: rem-calc(36);
			font-weight: 300;
			color: get-color(accent1);
			margin: 0 0 15px;
		}
	}

	p {
		
		@include breakpoint(medium) {
			margin: 0 0 21px;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		@include breakpoint(medium) {
			font-size: rem-calc(18);
			font-weight: 800;
		}
	}

	li {

		@include breakpoint(medium) {
			margin: 0 0 11px;
		}
	}

	.arrowLink {

		&::after {
			color: get-color(secondary);
		}
	}
}

.actionRow-links {
	font-size: rem-calc(18);
	background: get-color(accent1);
	margin: 0 -20px;
	padding: 25px 20px;

	@include breakpoint(large) {
		margin: 35px 0 0;
	}

	@include breakpoint(xxlarge) {
		margin-left: -40px;
		padding-left: 40px;
	}

	h3 {
		font-size: rem-calc(26);
		font-weight: 300;
		font-style: italic;
		color: #fff;
	}

	a {
		color: #fff;

		&:focus {
			outline-color: #fff;
		}
	}

	ul {

		@include breakpoint(large) {
			display: flex;
			justify-content: flex-start;
			margin: 0;
		}
	}

	li {
		font-size: rem-calc(16);

		@include breakpoint(large) {
			margin: 0;
			margin-right: 25px;
		}
	}
}