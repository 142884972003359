figure {
	font-size: rem-calc(14);
	color: $gray-darker;
	padding: 20px 7px;

	@include breakpoint(large) {
		padding: 30px 7px;
	}

	img {
		display: block;
		width: calc(100% + 14px);
		height: auto;
		margin: 0 -7px 1.2rem;
	}

	figcaption {
		line-height: 1.6;
	}

	strong {
		font-size: rem-calc(12);
		text-transform: uppercase;
		color: $gray-darkest;
		line-height: 2.4;
		display: block;
	}
}