.contactWidget {
	padding: 20px;
	margin: 20px -20px;
	box-shadow: 0 0 9px rgba(#000, 0.2);

	@include breakpoint(large) {
		padding: 30px 20px;
		margin: 30px -20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	@include breakpoint(xxlarge) {
		margin: 30px 0 30px -40px;
		padding: 25px 40px 40px;
	}

	h2 {
		font-weight: 300;
		min-width: 100%;
	}

	a {
		text-decoration: underline;
	}
}

.contactWidget-form {
	margin: 0 0 2rem;

	@include breakpoint(large) {
		width: 53%;
		margin: 0;
	}

	label {
		font-weight: 700;
		display: block;
		margin: 0 0 3px;
	}

	.eat-error {

		input[type=text] {
			border-color: red;

			&:focus {
				border-color: get-color(primary);
			}
		}
	}

	input[type=text] {
		height: 50px;
		margin: 0 0 7px;

		@include placeholder {
			color: $gray-darker;
		}
	}

	textarea {
		min-height: 100px;
		
		@include placeholder {
			color: $gray-darker;
		}
	}

	input[type=submit] {
		width: 100%;
		margin: 15px 0 0;
	}

	.error-text {
		color: red;
		font-size: rem-calc(14);
		display: block;
		margin: 0 0 1rem;
	}
}

.contactWidget-content {
	font-size: rem-calc(16);

	@include breakpoint(large) {
		width: 39%;
	}

	h3 {
		@extend h5;
	}
}

.eat-error-sum {
	border: 1px solid red;
	border-radius: 5px;
	padding: 25px;
	font-size: rem-calc(16);

	ul {
		margin-bottom: 0;
	}

	a {
		color: red;

		&:hover,
		&:focus {
			color: get-color(primary);
		}
	}
}