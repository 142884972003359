section.teaseRow {
	background: get-color(primary) url(../img/stripe-bg-2.jpg) 0 100% repeat-x;
	color: #fff;
	padding: 30px 0;
	max-width: 100%;
	overflow: hidden;

	@include breakpoint(large) {
		font-size: rem-calc(20);
		text-align: left;
	}

	@include breakpoint(xlarge) {
		padding: 85px 0 70px;
	}

	.button.secondary {

		&:focus {
			outline-color: #fff;
		}
	}
}

.teaseRow-inner {

	@include breakpoint(xlarge) {
		@include xy-grid();
	}
}

.teaseRow-left {
	font-size: rem-calc(18);
	margin: 0 0 35px;

	@include breakpoint(xlarge) {
		@include xy-cell(5);
		margin: 0;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(4, $gutters: 0);
		margin-left: 90px;
		padding-right: 65px;
	}

	h2 {
		font-size: rem-calc(28);
		font-weight: 300;
		color: #fff;

		@include breakpoint(xlarge) {
			font-size: rem-calc(38);
		}
	}
}

.teaseRow-right {
	font-size: rem-calc(16);
	position: relative;

	@include breakpoint(medium) {
		display: flex;
	}

	@include breakpoint(xlarge) {
		@include xy-cell(7);
		align-items: flex-start;
		padding: 15px 0 0 30px;
	}

	@include breakpoint(xxlarge) {
		padding-left: 90px;
		padding-right: 60px;
		@include xy-cell(7, $gutters: 0);
	}

	&.hasThumb {
		display: flex;
	}

	&::before {
		content: "";
		display: none;
		background: rgba(get-color(accent1), 0.2);
		position: absolute;
		top: -85px;
		left: 0;
		bottom: -85px;
		right: -999px;

		@include breakpoint(xlarge) {
			display: block;
		}
	}

	h2 {
		color: #fff;
		font-size: rem-calc(22);
		margin: 0 0 9px;
		font-weight: 700;
	}
}

.teaseRow-box {
	margin: 0 0 35px;
	position: relative;

	@include breakpoint(medium) {
		padding: 0 30px 0 0;
		margin: 0;
	}

	@include breakpoint(large) {
		@include xy-cell(6);
		padding: 0;
	}

	@include breakpoint(xxlarge) {
		padding-right: 25px;
	}

	.hasThumb & {

		
	}

	a {
		color: #fff;
	}

	.arrowLink {

		&:focus {
			outline-color: #fff;
		}

		&::after {
			color: get-color(secondary);
			
		}
	}
}

.teaseRow-thumb {
	position: relative;
	min-width: 100px;
	margin: 0 15px 0 0;
	
	@include breakpoint(medium) {
		min-width: 180px;
		margin: 0 25px 0 9px;
	}

	@include breakpoint(large) {
		margin-left: 25px;
	}

	@include breakpoint(xlarge) {
		width: 180px;
		margin-left: 20px;
		margin-right: 40px;
	}

	@include breakpoint(xxlarge) {
		margin-left: 0;
	}

	&::before {
		content: "";
		display: none;

		@include breakpoint(medium) {
			display: block;
			position: absolute;
			top: 9px;
			left: -9px;
			background: get-color(secondary);
			width: 100%;
			height: 100%;
		}
	}

	img {
		position: relative;
		object-fit: cover;
		width: 100px;
		height: 100px;

		@include breakpoint(medium) {
			width: 180px;
			height: 180px;
		}
	}
}

.teaseRow-torch {
	display: none;

	@include breakpoint(xlarge) {
		display: block;
		position: absolute;
		top: 9px;
		left: -30px;
	}
}