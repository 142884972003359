.tabWidget {
	padding: 20px 0;
	margin: 20px 0;

	@include breakpoint(large) {
		padding: 30px 0;
		margin: 30px 0;
	}

	@include breakpoint(xxlarge) {
		box-shadow: 0 2px 6px 0 rgba(#000, 0.2);
		margin: 30px 0 30px -25px;
		padding: 50px 25px;
	}
}

ul.tabWidget-tabs {
	list-style: none;
	padding: 0 1.25rem 21px;
	margin: 0 -1.25rem;
	//white-space: nowrap;
	overflow: auto;

	@include breakpoint(large) {
		white-space: normal;
		overflow: visible;
		margin: 0 0 25px;
		padding: 0;
	}

	li {
		vertical-align: top;
		white-space: normal;
		display: inline-block;
		margin: 0 7px 7px 0;

		&.on {
			
			a {
				color: get-color(primary);
				border-color: get-color(primary);

				&:hover {
					border-color: get-color(primary);
				}
			}
		}

		a {
			color: $gray-dark;
			font-weight: 700;
			display: block;
			padding: 7px 15px;
			border: 3px solid $gray-light;

			@include breakpoint(xlarge) {
				font-size: rem-calc(18);
			}

			&:hover {
				border-color: $gray-dark;
				text-decoration: none;
			}
		}
	}
}

.tabWidget-contentContainer {
	transition: height 2s ease-out;
}

.tabWidget-content {
	display: none;

	&.on {
		display: block;
	}

	h3 {
		@extend h4;
	}

	p {

		&:last-child {
			margin-bottom: 0;
		}
	}
}