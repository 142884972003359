.pagination {
	display: flex;
	flex-wrap: wrap;
	
	li {
		width: 39px;
		text-align: center;
		display: block;

		&.current {
			font-weight: 700;
		}
	}

	.pagination-previous a::before, .pagination-previous.disabled::before {
		content: "\f060";
		font-family: $icon-font;
		margin-right: 0;
		font-size: rem-calc(20);
		font-weight: 700;
	}

	.pagination-next a::after, .pagination-next.disabled::after {
		content: "\f061";
		font-family: $icon-font;
		margin-left: 0;
		font-size: rem-calc(20);
		font-weight: 700;
	}
}