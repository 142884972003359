.asideBox.asideShare {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 0 0 30px;

	h2 {
		font-weight: 700;
		text-transform: uppercase;
		color: $gray-darker;
		font-size: rem-calc(12);
		margin: 0 20px 0 0;
	}

	ul {
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: rem-calc(20);

		li {
			margin: 0 11px 0 0;
			line-height: 1.2;

			&.rss {

				a {
					
					&::before {
						content: "\f09e";
						font-family: $icon-font;
						display: inline-block;
						font-weight: 700;
					}
				}

				img {
					display: none;
				}
			}
		}

		a {
			color: get-color(primary);

			&:hover,
			&:focus {
				color: get-color(accent1);
			}
		}
	}
}