header:not(.sg-header) {
	background: get-color(primary);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 1;
	transition: transform 0.2s ease-out, opacity 0.2s ease-out;
	z-index: 100;

	@include breakpoint(xlarge) {
		background: #fff;
		position: relative;
		top: auto;
		left: auto;
	}

	&.scrolled {
			transform: translate(0, -100%);
			opacity: 0;
	}

	.EditMode & {
			//z-index: 1;
			position: relative;
			top: auto;
			left: auto;
	}
}

.headerShim {

	@include breakpoint(xlarge) {
		display: none;
	}

    .EditMode & {
        display: none !important;
    }
}

.header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
	height: 70px;
	max-width: 100%;

	@include breakpoint(xlarge) {
		padding: 25px 0;
		height: auto;
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}
}

.logo {

	a {

		&:focus {
			outline-color: #fff;

			@include breakpoint(xlarge) {
				outline-color: initial;
			}
		}
	}
	
	svg {
		height: 45px;
		width: auto;

		@include breakpoint(xlarge) {
			height: 60px;
		}
	}

	path {
		fill: #fff;

		@include breakpoint(xlarge) {
			fill: get-color(accent2);
		}
	}
}

.menuToggle {
	appearance: none;
	border: none;
	background: transparent;
	color: #fff;

	@include breakpoint(xlarge) {
		display: none;
	}

	&.on {

		&::before {
			content: "\f057";
		}
	}

	&::before {
		content: "\f0c9";
		font-family: $icon-font;
		display: block;
		text-align: center;
		font-size: rem-calc(25);
		line-height: 1;
		margin: 0 0 3px;
	}

	&:focus {
		outline-color: #fff;
	}

	span {
		font-size: rem-calc(9);
		color: rgba(#fff, 0.65);
		text-transform: uppercase;
		font-weight: 700;
		line-height: 1;
		display: block;
		text-align: center;
	}
}

.header-content {
	display: none;

	@include breakpoint(xlarge) {
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: center;
		flex-grow: 1;
	}
}

.topLogin {
	min-width: 100%;
	max-width: 100%;
	background: #fff;
	display: inline-block;
	padding: 19px 0 0;
	height: 68px;
	align-items: center;
	justify-content: center;
	font-size: rem-calc(14);
	font-weight: 700;
	color: $gray-dark;
	vertical-align: top;

	@include breakpoint(xlarge) {
		margin: 0 0 17px;
		height: auto;
		display: block;
		padding: 0;
		flex: 1 0 auto;
		flex-basis: 100%;
	}

	ul,
	ul.topLoginUl {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		@include breakpoint(xlarge) {
			justify-content: flex-end;
		}
	}

	.button,
	.button.hollow {
		margin: 0 0 0 15px;
		border-width: 2px;
		border-radius: 4px;
		padding: 6px 11px 5px;
		font-size: rem-calc(12);
		text-transform: uppercase;
		font-weight: 800;
	}
}

.topLinks {
	
	@include breakpoint(xlarge) {
		margin: 0;
		font-size: rem-calc(14);
		color: $gray-dark;
		min-width: 300px;
	}

	ul {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {

		@include breakpoint(xlarge) {
			margin: 0 0 0 25px;
		}
	}

	a {
		
		@include breakpoint(xlarge) {
			color: $gray-dark;
		}
	}
}

.topSocial {
	display: none;

	@include breakpoint(xlarge) {
		font-size: rem-calc(18);
		margin: 0 0 0 25px;
		display: block;
	}

	ul {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	li {

		@include breakpoint(xlarge) {
			margin: 0 0 0 13px;
		}
	}

	a {
		color: $gray-dark;

		&:hover,
		&:focus {
			color: get-color(primary);
		}
	}
}