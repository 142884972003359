.asideBox.asideEvents {

}

.asideEvent {
	margin: 0 0 30px;

	h3 {
		font-size: rem-calc(18);
		font-weight: 700;
		margin: 0 0 9px;

		a {
			color: $gray-darkest;
			text-decoration: underline;

			&:hover,
			&:focus {
				color: get-color(primary);
			}
		}
	}

	p.date {
		font-weight: 700;
		text-transform: uppercase;
		font-size: rem-calc(12);
	}
}