.arrowLink {
	font-weight: 800;
	color: get-color(accent1);

	&.back {

		&::after {
			display: none;
		}

		&::before {
			content: "\f060";
			display: inline-block;
			font-family: $icon-font;
			font-weight: 800;
			margin: 0 12px 0 0;
			color: get-color(accent3);
			color: get-color(primary);
		}
	}

	&::after {
		content: "\f061";
		display: inline-block;
		font-family: $icon-font;
		font-weight: 800;
		margin: 0 0 0 12px;
		color: get-color(accent3);
		color: get-color(primary);
	}
}

.button {
	font-weight: 700;
}

a,
span {

	&.pdf {
		position: relative;
		padding: 0 0 0 25px;
		display: inline-block;

		&::after {
			content: "\f1c1";
			font-family: $icon-font;
			display: inline-block;
			margin: 0;
			color: get-color(accent3);
			color: get-color(primary);
			font-weight: normal;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&.location {
		display: inline-block;
		padding: 0 0 0 25px;
		position: relative;

		&::before {
			content: "\f3c5";
			display: inline-block;
			margin: 0 11px 0 0;
			font-family: $icon-font;
			color: get-color(accent3);
			color: get-color(primary);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
	}

	&.calendar {
		display: inline-block;
		padding: 0 0 0 25px;
		position: relative;

		&::before {
			content: "\f073";
			display: inline-block;
			margin: 0 11px 0 0;
			font-family: $icon-font;
			color: get-color(accent3);
			color: get-color(primary);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			font-weight: 700;
		}
	}
}