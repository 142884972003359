section.relatedNews-section {
	background: $gray-lighter;
	padding: 25px 0;

	@include breakpoint(xlarge) {
		padding: 55px 0 70px;
	}
}

.relatedNews-inner {

	@include breakpoint(large) {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(xlarge) {
		@include xy-cell(10);
		@include xy-cell-offset(1, $gutters: 0);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(8);
		@include xy-cell-offset(2, $gutters: 0);
	}
}

.relatedNews-box {
	background: #fff;
	margin: 0 0 1rem;
	padding: 25px 20px;
	border-radius: $global-radius;
	box-shadow: 0 0 9px rgba(#000, 0.15);

	@include breakpoint(large) {
		width: calc(50% - 10px);
	}

	h2 {
		margin: 0 0 21px;
	}

	p {

		&:last-child {
			margin: 0;
		}
	}
}

.relatedNews {
	display: flex;
	flex-direction: column;
	font-size: rem-calc(16);
	margin: 0 0 21px;

	&:last-child {
		margin: 0;
	}

	.date {
		font-size: rem-calc(12);
		text-transform: uppercase;
		color: $gray-dark;
		font-weight: 700;
		order: 1;
		margin: 0;
	}

	h3 {
		order: 2;
		font-size: rem-calc(16);
		margin: 0;
		font-weight: 700;

		a {
			color: $gray-darker;
			text-decoration: underline;

			&:hover,
			&:focus {
				color: get-color(primary);
			}
		}
	}

	p {

		&:last-child {
			margin: 0;
		}
	}

	.caption {
		order: 3;
	}
}