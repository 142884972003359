html {
    //font-size: 62.5%;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: rem-calc(16);

    @include breakpoint(xlarge) {
        font-size: rem-calc(20);
    }
    
    &.noScroll {
        overflow: hidden;
		position: fixed;
		width: 100%;
    }
}

h1 {
    color: get-color(accent1);
    font-weight: 300;
}

h2 {
    color: get-color(accent1);
    font-weight: 700; 
}

h3 {
    color: get-color(accent1);
    font-weight: 300;
}

h4 {
    color: $gray-darkest;
    font-weight: 700;
}

h5 {
    color: $gray-darkest;
    font-weight: 700;
}

h6 {
    color: $gray-darkest;
    font-weight: 700;
}

p, ol, ul {

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
        margin-top: 2.4rem;
    }
}

section {
    padding: 15px 0;

    @include breakpoint(xlarge) {
        padding: 45px 0;
    }
}

.byline {
    font-size: rem-calc(16);
    color: $gray-dark;
    margin-top: -0.2rem;
}

.backToTop {
    display: none;

    &.on {
        display: block;
        position: fixed;
        bottom: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        text-align: center;
        border: 3px solid get-color(primary);
        background: #fff;

        @include breakpoint(xlarge) {
            display: none;
        }
    }

    &::after {
        content: "\f077";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $icon-font;
        color: get-color(primary);
    }
}