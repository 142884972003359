.featuredBox {
	font-size: rem-calc(16);
	padding: 20px 0;

	@include breakpoint(large) {
		font-size: rem-calc(18);
		padding: 30px 0;
	}

	&::after {
		content: "";
		display: block;
		clear: both;
	}

	h2 {

		a {
			color: get-color(accent1);
		}
	}

	p {

		&:last-child {
			margin-bottom: 0;
		}
	}

	a {
		color: get-color(accent1);

		&:hover,
		&:focus {
			color: get-color(primary);
		}
	}
}

.featuredBox-thumb {
	margin: 0 0 15px;

	@include breakpoint(medium) {
		float: right;
		margin: 0 0 15px 60px;
		width: 43%;
		height: auto;
	}
}