nav.mainNavRow {
	background: $gray-lighter;
	display: none;

	@include breakpoint(xlarge) {
		background: get-color(primary);
		padding: 50;
		display: block;
	}

	.grid-container {
		padding: 0;
	}
}

.mainNavRow-inner {
	white-space: nowrap;
	overflow: auto;

	@include breakpoint(xlarge) {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: normal;
		overflow: visible;
	}

	ul {
		margin: 0;
		padding: 13px 0;
		list-style: none;
		width: 100%;
		white-space: normal;
		display: inline-block;
	
		@include breakpoint(xlarge) {
			display: flex;
			font-size: rem-calc(16px);
			width: auto;
			padding: 0;
		}
	
		&.on {
			display: inline-block;
			vertical-align: top;
		}
	
		.mobileMenu-drawer & {
			//display: inline-block;
			vertical-align: top;
		}
	
		li {
			position: relative;

			@include breakpoint(xlarge) {
				margin: 0;
			}

			&.navAll {
				//display: none;

				.button {
					display: inline-block;
					margin-left: 25px;
					width: calc(100% - 50px);

					@include breakpoint(xlarge) {
						display: block;
						margin-left: 0;
					}
				}
			}

			&.hasChildren {

				&.on {

					&::after {

						@include breakpoint(xlarge) {
							content: "\f077";
						}
					}

					a,
					button {

						@include breakpoint(xlarge) {
							color: get-color(secondary);
						}
					}
				}

				&::after {
					content: "\f054";
					display: block;
					position: absolute;
					top: 50%;
					right: 25px;
					transform: translate(0, -50%);
					font-family: $icon-font;
					font-size: rem-calc(17);
					pointer-events: none;

					@include breakpoint(xlarge) {
						content: "\f078";
						top: 50%;
						right: 20px;
						transform: translate(0, -50%);
						font-size: rem-calc(18);
						color: get-color(secondary);
						font-weight: 700;
					}

					@include breakpoint(xxlarge) {
						right: 28px;
					}
				}

				a, button {
					line-height: inherit;

					@include breakpoint(xlarge) {
						padding-right: 47px;
					}

					@include breakpoint(xxlarge) {
						padding-right: 55px;
					}
				}
			}
		}
	
		a,
		button {
			display: block;
			padding: 9px 25px;
			font-size: rem-calc(16);
			font-weight: 700;
			color: $gray-darker;
			width: 100%;
			text-align: left;
			
			@include breakpoint(xlarge) {
				color: #fff;
				padding: 23px 20px;
				display: block;
				position: relative;
			}

			@include breakpoint(xxlarge) {
				padding: 23px 28px;
			}
	
			&:hover,
			&:focus,
			&:active {
	
				@include breakpoint(xlarge) {
					outline-color: #fff;
					outline-style: solid;
					outline-width: 2px;
					text-decoration: none;
				}
			}
		}
	
		ul {
			display: none;

			&.on {
				display: inline-block;
			}
		}
	}
}

.mainNavRow-inner .mainNav-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	display: block;
	width: auto;
	padding: 0 9px;

	span {
		position: absolute;
		top: 0;
		left: 0;
	}

	&::after {
	
		@include breakpoint(xlarge) {
			content: "\f078";
			display: block;
			position: absolute;
			top: 50%;
			right:20px;
			transform: translate(0, -50%);
			font-family: $icon-font;
			font-size: rem-calc(18);
			color: get-color(secondary);
			font-weight: 700;
		}
	}
}

.mobileNavTop {
	white-space: nowrap;
}

.navBack {
	height: 68px;
	line-height: 68px;
	background: #fff;
	display: inline-block;
	vertical-align: top;
	width: 100%;
	padding: 10px 0 0;

	.back {
		padding: 15px 25px 15px 57px;
		display: block;
		font-size: rem-calc(18);
		font-weight: 700;
		color: get-color(accent1);
		position: relative;
		width: 100%;
		text-align: left;

		&::before {
			content: "\f060";
			display: inline-block;
			margin: 0;
			position: absolute;
			top: 50%;
			left: 25px;
			transform: translate(0, -50%);
			font-family: $icon-font;
			color: get-color(accent3);
			color: get-color(primary);
			font-size: rem-calc(20);
		}
	}
}

.menuDrawer {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #fff;
	box-shadow: 0 0 9px rgba(#000, 0.2);
	padding: 25px 0;

	@include breakpoint(xxlarge) {
		padding: 40px 0;
	}
}

.menuDrawer-inner {
	display: flex;

	@include breakpoint(xxlarge) {
		@include xy-cell(11, $gutters: 0);
		@include xy-cell-offset(1, $gutters: 0);
	}
}

.menuDrawer-tertiary {
	display: flex;
	justify-content: space-between;
	width: 64%;
}

.menuDrawer-left {
	@include xy-cell(4, $gutters: 0);
	font-size: rem-calc(18);
	font-weight: 700;
	padding: 0 50px 0 0;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin: 0 0 11px;
			position: relative;

			&.hasChildren {
				
				&::after {
					content: "\f054";
					display: block;
					position: absolute;
					top: 50%;
					right: 25px;
					transform: translate(0, -50%);
					font-family: $icon-font;
					pointer-events: none;
					font-size: rem-calc(17);
				}
			}

			&.on {

				&::after {
					color: get-color(primary);
				}

				a, button {
					color: get-color(primary);
				}
			}
			
			ul {
				display: none;
			}
		}
	}

	a, button {
		color: $gray-darker;
		display: block;
		width: 100%;
		text-align: left;

		&:hover,
		&:focus,
		&:active {
			color: get-color(primary);
		}
	}
}

.menuDrawer-mid {
	@include xy-cell(4, $gutters: 0);
	font-size: rem-calc(16);

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			margin: 0 0 11px;
			
			ul {
				display: none;
			}
		}
	}

	a {
		color: $gray-darker;

		&:hover,
		&:focus,
		&:active {
			color: get-color(primary);
			outline-color: get-color(primary);
		}
	}

	.button {
		margin: 0 0 4px;
		padding: 9px 15px;
		font-size: rem-calc(14);
	}
}

.menuDrawer-right {
	@include xy-cell(8, $gutters: 0);
	padding-left: 35px;
}

.navFeatureBox {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.navFeatureBox-thumb {
	min-width: 130px;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 9px;
		left: -9px;
		height: 100%;
		width: 100%;
		background: get-color(secondary);
	}

	img {
		object-fit: cover;
		height: 130px;
		width: 130px;
		position: relative;
	}
}

.navFeatureBox-content {
	padding: 0 0 0 21px;
	font-size: rem-calc(16);
	
	@include breakpoint(xxlarge) {
		padding-right: 80px;
	}

	h2 {
		font-size: rem-calc(24);
		color: get-color(accent1);
		font-weight: 300;
		margin: 0 0 11px;
	}

	a.arrowLink {
		font-size: rem-calc(18);
	}
}

.mobileMenu-drawer {
	display: none;
	position: fixed;
	top: 70px;
	left: 0;
	width: 100%;
	height: calc(100% - 70px);
	background: #fff;
	z-index: 100;
}

.mainSearch-toggle {
	appearance: none;
	border: none;
	background: transparent;
	color: #fff;
	cursor: pointer;
	border-radius: 5px;
	display: none;
	transition: background-color 0.2s ease-out, color 0.2s ease-out;

	@include breakpoint(xlarge) {
		margin: 0;
		padding: 11px 21px;
		display: block;
	}

	&.on {

		&::after {
			content: "\f057";
			font-weight: normal;
		}
	}

	&:hover {
		background: #fff;

		@include breakpoint(xlarge) {
			color: get-color(primary);
		}
	}

	&:focus {
		outline-color: #fff;
		outline-style: solid;
		outline-width: 2px;
	}

	&::after {
		content: "\f002";
		font-family: $icon-font;
		font-weight: bold;
	}
}

.mainSearch-drawer {
	background: get-color(primary);

	@include breakpoint(xlarge) {
		display: none;
		height: auto;
		position: absolute;
		width: 100%;
		top: 100%;
		left: 0;

	}
}

.mainSearch-drawer-inner {
	color: #fff;
	font-size: rem-calc(14);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	height: 130px;

	@include breakpoint(xlarge) {
		padding: 25px 0;
		display: flex;
		@include xy-cell(10);
		@include xy-cell-offset(1);
	}

	@include breakpoint(xxlarge) {
		@include xy-cell(8);
		@include xy-cell-offset(2);
	}
	
	.or {
		display: none;

		@include breakpoint(xlarge) {
			display: block;
			font-size: rem-calc(18);
			font-weight: 700;
			text-transform: uppercase;
			margin: 0;
			width: 80px;
			text-align: center;
		}
	}

	.dropDownWrapper {
		position: relative;
		border-radius: $global-radius;
		background: #fff;
		flex-grow: 1;

		@include breakpoint(xlarge) {
			margin: 0;
		}

		&::after {
			content: "\f078";
			font-family: $icon-font;
			color: $gray-darker;
			font-size: rem-calc(20);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			pointer-events: none;
			width: 40px;
			text-align: center;

			@include breakpoint(xlarge) {
				font-size: rem-calc(24);
				color: get-color(primary);
			}
		}

		select {
			width: 100%;
			appearance: none;
			border: none;
			background: transparent;
			height: 40px;
			padding: 0 11px;
			color: $gray-darker;
			margin: 0;
			font-size: rem-calc(16);

			@include breakpoint(xlarge) {
				width: 220px;
				height: 50px;
				padding: 0 15px;
			}

			&:focus {
				outline-color: #fff;

				@include breakpoint(xlarge) {
					outline-color: red;
				}
			}
		}
	}
}

.mainSearch-left {
	display: flex;
	justify-content: space-between;
	margin: 0 0 15px;
	width: 100%;

	@include breakpoint(xlarge) {
		width: auto;
		margin: 0;
		flex-grow: 1;
	}

	input[type=submit] {
		margin: 0 0 0 9px;
		min-width: 90px;
		font-size: rem-calc(16);
		padding: 0;

		@include breakpoint(xlarge) {
			font-size: rem-calc(18);
			font-weight: 700;
			margin: 0 0 0 11px;
			min-width: 1px;
			padding: 0 21px;
		}

		&:focus {
			outline-color: #fff;
		}
	}
}

.mainSearch-input {
	position: relative;
	flex-grow: 1;
	color: $gray-darker;
	font-size: rem-calc(14);

	@include breakpoint(xlarge) {
		width: auto;
		padding: 0;
		margin: 0;
		display: flex;
	}

	&:focus-within {
		outline-color: red;
	}

	&.focus {

		label {
			top: -4px;
			transform: translate(0, 0);
		}
	}

	label {
		position: absolute;
		top: 50%;
		left: 15px;
		transform: translate(0, -50%);
		transition: top .2s ease-out, transform .2s ease-out;
		pointer-events: none;
		color: $gray-darker;
		font-size: rem-calc(14);
		font-weight: 700;
	}

	input[type=text] {
		height: 40px;
		padding: 11px 11px 0;
		font-size: rem-calc(16);
		background: #fff;
		appearance: none;
		border: none;
		width: 100%;
		margin: 0;
		border-radius: $global-radius;

		@include placeholder {
			color: $gray-darker;
		}

		@include breakpoint(xlarge) {
			height: 50px;
			flex-grow: 1;
			padding: 9px 40px 0 15px;
		}

		&:focus {
			outline-color: #fff;
		}

		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			height: 0;
			width: 0;
		}

		&:focus {
			outline-color: #fff;

			@include breakpoint(xlarge) {
				outline-color: red !important;
			}
		}
	}

	.clear {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(0, -50%);
		appearance: none;
		border: none;
		background: transparent;
		color: $gray-darker;
		cursor: pointer;
		height: 30px;
		width: 40px;
		line-height: 28px;
		text-align: center;
		border-radius: 5px;
		display: none;

		@include breakpoint(xlarge) {
			color: get-color(primary);
			font-weight: 400;
		}

		&.on {
			display: block;
		}

		&:hover {
			background: #fff;
		}

		&:focus {
			outline-color: #fff;

			@include breakpoint(xlarge) {
				outline-color: red;
			}
		}

		&::after {
			content: "\f00d";
			font-family: $icon-font;
			font-size: rem-calc(20);

			@include breakpoint(xlarge) {
				font-size: rem-calc(24);
			}
		}
	}
}

.mainSearch-right {
	display: flex;
	justify-content: space-between;
	margin: 0 0 15px;
	width: 100%;
	position: relative;

	@include breakpoint(xlarge) {
		width: auto;
		margin: 0;
	}

	label {
		position: absolute;
		top: -4px;
		left: 15px;
		transition: top .2s ease-out, transform .2s ease-out;
		pointer-events: none;
		color: $gray-darker;
		font-size: rem-calc(14);
		font-weight: 700;
	}

	.dropDownWrapper {

		select {
			padding-top: 11px;
		}
	}

	input[type=submit] {
		margin: 0 0 0 9px;
		min-width: 90px;
		font-size: rem-calc(16);
		padding: 0;

		@include breakpoint(xlarge) {
			font-size: rem-calc(18);
			font-weight: 700;
			margin: 0 0 0 11px;
			min-width: 1px;
			padding: 0 21px;
		}

		&:focus {
			outline-color: #fff;
		}
	}
}