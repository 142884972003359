section.landing {

	@include breakpoint(medium) {
		padding: 0 0 150px;
	}
}

.landingIntro {
	line-height: 1.7;
	margin: 0 0 2rem;

	@include breakpoint(medium) {
		font-size: rem-calc(20);
		margin: 0 0 50px;
		padding: 30px 0;
	}

	@include breakpoint(large) {
		//@include xy-cell(7);
		//@include xy-cell-offset(1);
		display: flex;
		align-items: center;
	}

	@include breakpoint(xlarge) {
		//@include xy-cell-offset(2);
	}

	h1 {

		@include breakpoint(medium) {
			font-size: rem-calc(38);
			font-weight: 300;
			color: get-color(accent1);
			margin: 0 0 15px;
		}
	}
}

.landingIntro-lead {

	@include breakpoint(large) {
		background: get-color(secondary);
		@include xy-cell(6);
		padding: 35px 35px 35px 15px;
		position: relative;
		box-shadow: 0 0 9px rgba(#000, 0.2);
	}

	@include breakpoint(xlarge) {
		padding: 35px 60px 35px 0;
		@include xy-cell(5);
		@include xy-cell-offset(1);
	}

	@include breakpoint(xxlarge) {
		padding-left: 15px;
		@include xy-cell(4);
		@include xy-cell-offset(1);
	}

	&::before {
		content: "";
		
		@include breakpoint(large) {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -999px;
			right: 100%;
			background: get-color(secondary);
		}
	}

	h1 {

		@include breakpoint(large) {
			color: get-color(accent1);
			margin: 0;
		}
	}
}

.landingIntro-torch {
	display: none;

	@include breakpoint(xlarge) {
		display: block;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(50%, -50%);
	}
}

.landingIntro-content {

	@include breakpoint(large) {
		@include xy-cell(6);
		padding: 0 0 0 25px;
	}

	@include breakpoint(xxlarge) {
		padding: 0 60px 0 25px;
	}

	p {
		
		&:last-child {
			margin: 0;
		}
	}
}

.landingCard-wrapper {

	@include breakpoint(medium) {
		//display: flex;
		//flex-wrap: wrap;
		//justify-content: flex-start;
		margin: 0 -10px;
	}

	@include breakpoint(xxlarge) {
		//@include xy-cell(10);
		//@include xy-cell-offset(1);
		margin-left: 8%;
		margin-right: 8%;
	}
}

.landingCard {
	border-radius: 5px;
	box-shadow: 0 0 7px rgba(#000, 0.1);
	padding: 25px 25px 30px;
	color: $gray-darker;
	line-height: 1.7;
	display: block;
	margin: 0 0 15px;
	width: 100%;

	@include breakpoint(medium) {
		width: calc(50% - 20px);
		display: flex;
		flex-direction: column;
		margin: 0 10px 15px;
		font-size: rem-calc(18);
	}

	@include breakpoint(xlarge) {
		width: calc(33.3333% - 20px);
	}

	&:hover,
	&:focus {
		text-decoration: none;
		color: $gray-darker;
		box-shadow: 0 0 7px rgba(get-color(accent1), 0.6);
	}

	p {
		margin: 0 0 25px;

		&:last-child {
			margin: 0;
		}
	}

	.text-right {
		margin-top: auto;
	}

	h2 {
		color: get-color(accent1);

		@include breakpoint(medium) {
			font-size: rem-calc(24);
			line-height: 1.2;
			margin: 0 0 15px;
		}

		@include breakpoint(xlarge) {
			font-size: rem-calc(26);
		}
	}

	.arrowLink {
		color: get-color(accent1);
		font-weight: 700;
	}
}